import { useStaticQuery, graphql } from "gatsby"

export const useVideos = () => {
  const response = useStaticQuery(
    graphql`
      query MyQuery {
        featuredVideos: allSanityVideos(
          sort: { fields: title }
          filter: { featured: { eq: true } }
        ) {
          nodes {
            id
            title
            url
            category
            featured
            cover_image {
              asset {
                fluid(maxWidth: 350) {
                  ...GatsbySanityImageFluid
                  src
                }
              }
            }
          }
        }
        recentVideos: allSanityVideos(
          limit: 3
          sort: { fields: _updatedAt, order: DESC }
        ) {
          nodes {
            id
            title
            url
            category
            featured
            cover_image {
              asset {
                fluid(maxWidth: 350) {
                  ...GatsbySanityImageFluid
                  src
                }
              }
            }
          }
        }
        categoryVideos: allSanityVideos(sort: { fields: [category, title] }) {
          nodes {
            id
            title
            url
            category
            featured
            cover_image {
              asset {
                fluid(maxWidth: 350) {
                  ...GatsbySanityImageFluid
                  src
                }
              }
            }
          }
        }
      }
    `
  )
  return response
}
